import Feature from "../feature";
import gem from "../../images/gem.png";
import money from "../../images/money.png";
import success from "../../images/success.png";
import train from "../../images/playing-train.jpg";
import checklist from "../../images/checklist.png";
import dinosaur from "../../images/dinosaur.png";
import story from "../../images/story.png";
import letters from "../../images/learning-letters.png";
import happiness from "../../images/happiness.png";
import chat from "../../images/chat.png";

// All homepage style features
export default function UpgradeFeatureWapper({ includeCourseCTA = true, onSubmit }) {
  return (
    <div>
      <Feature imagePath={story} reverse={true} imageLarge={false}>
        <h3>Who is it for?</h3>
        <p>
          Our app is designed for parents & caregivers of children aged 0-5 years old. It has been
          designed by Kate Burgess, a Speech & Language Therapist, to help busy parents understand
          how and why strategies work. The Pippin mobile app is so much more than just a course -
          with activities & games, demonstrations and action plans, plus assessment tools and
          progress trackers you'll be using Speech Therapy strategies by the end of the day 1.
        </p>
      </Feature>

      <Feature imagePath={checklist} reverse={false} imageLarge={false}>
        <h3>Why use the Pippin app?</h3>
        <p>
          By using the Pippin App you'll have access to range of advantages:
          <ul style={{ marginTop: 15 }}>
            <li>Immediate access to support with no waiting lists</li>
            <li>The flexibility to learn at your own pace, at a time that suits you</li>
            <li>As many opportunities as you like to re-watch and re-visit each strategy</li>
            <li>
              All parents and caregivers have access - not just the one person who could attend in
              person therapy sessions
            </li>
            <li>
              Cost effective support: access to 5 strategies for roughly the price of one in person
              therapy session
            </li>
          </ul>
        </p>
      </Feature>

      <Feature imagePath={letters} reverse={true} imageLarge={false}>
        <h3>Helping your child learn to talk</h3>
        <p>
          Children whose parents/carers use Pippin:
          <ul style={{ marginTop: 15 }}>
            <li>Have more frequent access to high quality interactions </li>
            <li>Find learning language and communication skills easier</li>
            <li>
              Learn through play and every day activities and routines - so access support all the
              time!
            </li>
          </ul>
        </p>
      </Feature>

      <Feature imagePath={happiness} reverse={false} imageLarge={false}>
        <h3>Pippin helps parents too!</h3>
        <p>
          When you download Pippin, you can stop scrolling for ideas and be confident that you're
          accessing high quality, evidence based support from a qualified Speech & Language
          Therapist. Parents who use Pippin:
          <ul style={{ marginTop: 15 }}>
            <li>
              No longer feel confused when it comes to developing their child's language and
              communication skills
            </li>
            <li>
              Learn and actually USE simple tools and strategies that they apply to their daily
              interactions and routines with their child
            </li>
            <li>
              Feel confident and experience peace of mind that they are giving their child a
              language rich and communication friendly environment in which to thrive
            </li>
            <li>Are giving their child the very best start they can</li>
          </ul>
        </p>
      </Feature>

      <Feature imagePath={chat} reverse={true} imageLarge={false}>
        <h3>How does Pippin help my child?</h3>
        <p>
          Communication is a basic human right and improving your child's communication skills can
          support them in lots of areas. You can help your child learn to:
          <ul style={{ marginTop: 15 }}>
            <li>Tell you what they want and need</li>
            <li>Let you know when they are hurt and feeling poorly</li>
            <li>
              Be able to use their communication skills to interact with their peers and make
              friends
            </li>
          </ul>
        </p>
      </Feature>

      <Feature imagePath={train} reverse={false} imageLarge={true}>
        <h3>What do people say about Pippin?</h3>

        <p>
          “Great app with loads of relevant information to support both children and their parents!”
          - Katie Chilcott
        </p>

        <p>“An invaluable aid…..well thought out with lots of helpful content” - Lymeside</p>

        <p>
          “This app is incredible, our son is 3 and non-verbal and since using this app he has come
          on leaps and bounds. The app is super easy to use and worth every penny” - amywebb1234
        </p>

        <p>
          “[My Son] is making steady progress as a result of the strategies we use. It's also
          completely transformed my confidence in how to support my child, am reassured that I am
          supporting him in the best way I can.” - Anonymous parent
        </p>
      </Feature>

      {includeCourseCTA ? (
        <Feature imagePath={money} reverse={true}>
          <h3>Course cost</h3>
          <p>
            The Pippin Speech app is free to download. Access to our evidence based course is
            available to purchase for only £19.99.
            <br />
            <br />
            Private Speech Therapy is expensive! Five strategies would usually be covered in 5 face
            to face therapy sessions, costing upto £500. Access to the premium version of the app is
            priced at less than one face to face therapy session, and you can access all of it
            straight away.
            <form onSubmit={onSubmit} style={{ marginTop: 20 }}>
              <input type="submit" className={"buttonGreen"} value="BUY NOW" />
            </form>
          </p>
        </Feature>
      ) : null}
    </div>
  );
}
